body {
  font-family: Sf Pro Text, sans-serif;
  margin: 0;
  padding: 0;
  letter-spacing: 1px;
  scroll-behavior: smooth;
  box-sizing: border-box;
}

.app__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.app__wrapper::before, .app__wrapper::after {
  content: '';
  position: absolute;
  top: 0; 
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  opacity: 0.4;
  pointer-events: none;
}

.app__wrapper::before {
  background-image: url('./assets/backgroundImg.jpg');
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 20%;
}

.header-container {
  text-align: center;
  margin-top: 40px;
  padding: 28px;
}

.header-title {
  letter-spacing: 4px;
  font-size: 48px;
  font-weight: 500;
  margin-bottom: 24px;
}

.teal-color {
  color: #02bec5;
}

.serial-number-container {
  text-align: left;
  position: relative;
  margin: 20px;
}

.serial-number-title {
  color: #828282;
  font-size: 20px;
}

.serial-number-content {
  margin-top: 8px;
  padding: 12px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.serial-number {
  font-size: 28px;
  color: #636363;
  font-weight: 400;
  margin: 0;
  z-index: 100;
  cursor: pointer;
}

.copy-icon {
  font-size: 24px;
  cursor: pointer;
  color: #02bec5;
  position: absolute;
  right: 24px;
  transform: scale(1.2);
}

.serial-number-content::before {
  content: "";
  position: absolute;
  border-style: solid;
  border-color: #02bec5;
  border-width: 0 4px 4px 0;
}

.serial-number-content::after {
  content: "";
  position: absolute;
  border-style: solid;
  border-color: #02bec5;
  border-width: 4px 0 0 4px;
}

.serial-number-content::before {
  bottom: -4px;
  right: -4px;
  width: 460px;
  height: 60px;
}

.serial-number-content::after {
  top: -4px;
  left: -4px;
  width: 460px;
  height: 60px;
}

.links-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-size: cover;
}

.links-section {
  margin-bottom: 60px;
}

.section-title h2 {
  display: inline; 
  font-weight: normal;
  font-size: 28px;
  margin: 0;
}

.section-title_bold {
  font-weight: bold;
}

.title-line {
  height: 2px;
  background-color: #02bec5;
  width: 200px; 
  margin-top: 5px; 
}

.links-list {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  height: 100%;
}

.link-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  background: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 28px;
  width: 30%;
  height: 180px;
  position: relative;
  overflow: hidden;
}

.link-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.9;
  filter: brightness(0.7);
}

.link-text {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 90%; 
  padding-left: 10%;
  color: white;
  text-align: left;
  z-index: 10;
}

.link-description {
  font-size: 28px; 
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  letter-spacing: 1.5px;
}

.external-icon {
  font-size: x-large;
  position: absolute;
  top: 20px;
  right: 20px;
  color: #fff;
  transform: scale(1.4);
}

/* TABLETS, LARGE SMARTPHONES */
@media (max-width: 768px) {

  .app-container {
    padding: 0 10%;
    height: auto;
  }
  
  .serial-number-content::before {
    width: 400px;
  }
  
  .serial-number-content::after {
    width: 400px;
  }

  .links-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    justify-content: center;
    gap: 12px;
  }

  .link-item {
    width: 45%;
    height: 168px;
  }
}

@media (max-width: 680px) {
  .serial-number-content::before {
    width: 340px;
  }
  
  .serial-number-content::after {
    width: 340px;
  }

}

@media (max-width: 655px) {
  .link-description {
    font-size: 24px;
  }

  .header-container {
    padding: 20px;
  }

  .links-list {
    gap: 16px;
  }

  .link-item {
    width: 40%;
    height: 124px;
  }
}


/* SMALL PHONES, LARGE SMARTPHONES */


@media (max-width: 580px) {

  .copy-icon {
    right: 8px;
    transform: scale(1);
  }

  .serial-number-container {
    margin: 0;
  }

  .serial-number {
    font-size: 20px;
  }
  
  .serial-number-content::before {
    height: 50px;
    width: 268px;
  }
  
  .serial-number-content::after {
    height: 50px;
    width: 268px;
  }
}

@media (max-width: 500px) {

  .link-description {
    font-size: 20px;
  }

  .copy-icon {
    right: 12px;
  }

  .serial-number {
    font-size: 16px;
  }
  
  .serial-number-content::before {
    height: 44px;
    width: 240px;
  }
  
  .serial-number-content::after {
    height: 44px;
    width: 240px;
  }
}

@media (max-width: 440px) {
  .copy-icon {
    transform: scale(0.8);
    right: 4px;
  }
  
  .serial-number-content::before {
    width: 200px;
  }
  
  .serial-number-content::after {
    width: 200px;
  }
}

@media (max-width: 360px) {
  
  .serial-number-content::before {
    width: 160px;
  }
  
  .serial-number-content::after {
    width: 160px;
  }
}